export default defineNuxtRouteMiddleware((to, from) => {
    // TODO: uncomment when guest login feature is ready
    return

    // const userStore = useUserStore()
    // if (userStore.isGuest) {

    //     return navigateTo({
    //         name: PAGE.NUDGE_REGISTER,
    //         query: {
    //             'redirectTo': to.path
    //         }
    //     })
    // }
})
